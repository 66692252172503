@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  @apply text-gray-500;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, h6 {
  @apply tracking-tight font-extrabold text-gray-900;
}

h4 {
  @apply text-xl;
}

p {
  @apply my-5;
  line-height: 1.75;
}

.app {
  display: flex;
  height: 100vh;
}

.aside {
  flex-basis: 18rem;
  @apply flex-grow-0 flex-shrink-0 p-5 overflow-y-auto box-border font-medium text-base lg:text-sm bg-white;

  &-category {
    @apply px-2 block mt-5 mb-2 uppercase tracking-wide font-semibold text-sm lg:text-xs text-gray-900;
  }

  li {
    a {
      @apply px-2 py-2 transition-colors duration-200 relative block hover:text-gray-900 text-gray-500 rounded;

      &.active {
        @apply text-blue-600;
        @apply bg-blue-100;
      }
    }
  }

  input {
    @apply block p-2 w-full rounded border-2 border-gray-100 text-gray-900;
  }

  &-open {
    @apply fixed;
    top: 0.5rem;
    right: 0.5rem;

    @media all and (min-width: 1024px) {
      display: none;
    }
  }

  &-close {
    @apply float-right -mt-4;
    transition: 0.2s;

    @media all and (min-width: 1024px) {
      display: none;
    }

    &:hover {
      transform: rotate(180deg);
    }
  }

  @media all and (max-width: 1024px) {
    @apply fixed h-full;
    z-index: 10;
    top: 0;
    right: 0;
    transform: translateX(100%);
    transition: 0.2s;

    &.open {
      transform: translateX(0);
    }
  }

  &-nav {
    @apply bg-white p-3 h-screen flex flex-col shadow-lg;
    width: 20rem;
    max-width: 100%;
    z-index: 10;

    a {
      @apply text-gray-900 py-2 px-4;

      &:hover {
        @apply text-blue-600;
      }
    }

    > * {
      @apply mt-2;
    }
  }
}

// card
.card {
  @apply bg-white rounded-xl shadow-xl mb-4 text-sm;

  &-title {
    @apply uppercase font-medium pt-6 pb-2 px-6 text-gray-900;

    + .card-content {
      @apply pt-2;
    }
  }

  &-content {
    @apply py-6 px-6;
  }

  &-actions {
    @apply pb-4 px-6;
  }
}

.flex-column {
  @apply flex flex-col;
}

// form
form {
  @apply text-sm;
}

input {
  @apply rounded py-2 px-3 block mb-2 border;
}

button {
  @apply rounded py-2 px-4 cursor-pointer font-bold mr-1 mb-2 shadow text-sm text-gray-900;

  &:hover {
    @apply bg-gray-100;
  }

  &.primary {
    @apply bg-blue-600 text-white;

    &:hover {
      @apply bg-blue-500;
    }
  }

  &:disabled {
    @apply cursor-not-allowed opacity-50;
  }

  &.toggle {
    background: none;
    box-shadow: none;
  }
}

.multiselect {
  &-container {
    @apply relative mt-2;

    ul {
      width: 30rem;
      border-radius: 0 0 1rem 1rem;
      @apply shadow-lg absolute py-2 px-0 bg-white text-sm;
      top: 2.4rem;

      li {
        @apply bg-white py-2 px-4 cursor-pointer;

        &.active {
          @apply bg-blue-100 text-blue-600;
        }

        &:hover {
          @apply bg-blue-600 text-white;
        }
      }
    }
  }

  &-backdrop {
    @apply fixed w-full h-full;
    top: 0;
    left: 0;
  }
}

// detail page
.example-container {
  @apply flex -mx-3 flex-1;

  &.list {
    @apply flex-col;

    .CodeMirror {
      max-height: 50vh;
    }

    > * {
      @apply mb-4;
    }
  }

  &.grid {
    @apply overflow-hidden;
  }
}

.example-container > * {
  @apply flex-1 mx-3;
}

.breadcrumb {
  @apply flex justify-between items-center mb-4;
}

// nav
.nav {
  @apply mb-2 bg-white box-border rounded py-1 px-3 shadow;

  a {
    @apply py-2 px-4 text-gray-900 inline-block;

    &:hover {
      @apply text-blue-600;
    }

    &.active {
      @apply text-blue-600;
    }
  }
}

// table
table {
  @apply bg-white rounded p-2 w-full shadow;

  th {
    @apply text-left text-gray-900 font-medium uppercase text-sm;
  }

  td, th {
    @apply py-2 px-4;
  }

  td {
    @apply text-gray-500;
  }
}

// grid
.grid-toggle {
  button {
    @apply m-0 rounded-none bg-white border-r py-1 px-3 shadow;

    &:first-of-type {
      border-radius: 0.4rem 0 0 0.4rem;
    }

    &:last-of-type {
      border-radius: 0 0.4rem 0.4rem 0;
      @apply border-r-0;
    }

    &:hover, &.active {
      @apply bg-blue-600 text-white shadow-lg;
    }

    svg {
      width: 0.8rem;
    }
  }
}

// alert
.alert {
  @apply py-3 px-5 rounded bg-blue-100 text-blue-600 mb-4 box-border text-sm shadow;
}

// console
.console-container {
  @apply fixed overflow-y-auto text-sm shadow rounded;
  top: 0.5rem;
  right: 0.5rem;
  z-index: 5;

  ul {
    font-family: monospace;
    width: 20rem;
    @apply overflow-y-auto;
  }

  li {
    @apply py-2 px-5 border-b bg-white;

    &.error {
      @apply bg-red-100 text-red-600;
    }
  }
}

// typography
.announcement {
  @apply text-lg mb-6 text-gray-700;
}

// modal
.modal-backdrop {
  @apply fixed w-full h-full flex items-center justify-center;
  top: 0;
  left: 0;
  background: rgba(100, 100, 100, 0.5);
  z-index: 4;
}

.modal-body {
  @apply bg-white shadow-lg p-6 px-8 rounded-lg;
  max-width: 40rem;
}

.tabs {
  &-container {
    @apply py-0 px-2;

    button {
      @apply shadow-none rounded-none m-0 border-b-2 px-4 font-light;

      &:hover {
        @apply text-blue-600;
      }

      &.selected {
        @apply border-blue-600;
      }

      &.disabled {
        @apply cursor-not-allowed text-gray-300;
      }

      &:hover {
      }
    }
  }

  &-options {
    @apply bg-gray-100 rounded overflow-hidden mb-3;
  }
}