@import '~codemirror/lib/codemirror.css';
@import '~codemirror/theme/material.css';

.cm-s-material.CodeMirror,
.cm-s-material .CodeMirror-gutters {
  background-color: #19203a;
}

.react-codemirror2 {
  height: 100%;
}

.CodeMirror {
  border-radius: 5px;
  font-size: 0.9rem;
  height: 100%;
  overflow-y: auto;

  pre {
    padding: 2px 4px !important;
  }

  &-activeline {
    background: rgba(0, 0, 0, 0.3);
  }
}

.cm-s-material .cm-string {
  color: #23ff79;
}

.codemirror {
  &-container {
    position: relative;
  }

  &-unsaved {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    display: flex;
    z-index: 3;

    button {
      margin: 0;
      font-size: 1.1rem;
      padding: 0.2rem 0.8rem;
      background: #7aeaae;
      color: #111111;
    }
  }
}